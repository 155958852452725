import { AccountId, PrettyPlayerBundle, PlayerBundle, PlayerId, TeamId } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function playerBundle__server__getPrettyPlayerBundlesFromPlayerIds(p: {
  playerIds: PlayerId[];
  teamId: TeamId;
}): Promise<PrettyPlayerBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const a1 = await Promise.all(
    p.playerIds.map(id =>
      h.PlayerBundle.query({
        where: [
          { derived: { linkedPlayers: { [id]: { teamId: ["==", p.teamId] } } } },
          { derived: { linkedPlayers: { [id]: { status: ["==", "active"] } } } }
        ]
      })
    )
  );

  if (!a1) {
    return [];
  }

  const playerBundles: PlayerBundle[] = [];
  a1.forEach(a => {
    playerBundles.push(...a.docs);
  });
  const prettyPlayerBundles = await Promise.all(playerBundles.map(pb => fetchPrettyPlayerBundle(pb)));
  return _.compact(prettyPlayerBundles);
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPrettyPlayerBundlesFromPlayerIds.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
