import { AccountInfo, PlayerBundleId } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers, sendFlexRequest } from "../../helpers";
import { ObjectKeys } from "../../utils";
import { maybeRefreshFlexPlayer } from "../flex/helpers/maybeRefreshFlexPlayer";

export async function playerBundle__server__updateVirtualAthleteAccount(p: {
  playerBundleId: PlayerBundleId;
  virtualAthleteAccount: AccountInfo;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.virtualAthleteAccount.profileImageUri === "") {
    p.virtualAthleteAccount.profileImageUri = h._MagicDeleteValue;
    p.virtualAthleteAccount.profileImageUriSmall = h._MagicDeleteValue;
  }

  await h.PlayerBundle.update({
    id: p.playerBundleId,
    doc: {
      virtualAthleteAccount: p.virtualAthleteAccount
    }
  });

  const playerBundle = await h.PlayerBundle.getDoc(p.playerBundleId);

  await Promise.all(
    ObjectKeys(playerBundle?.derived.linkedPlayers ?? {}).map(async playerId => {
      await h.Player.update({
        id: playerId,
        doc: {
          virtualAthleteAccount: p.virtualAthleteAccount
        }
      });
      sendFlexRequest("flex__server__maybeRefreshFlexPlayer", {
        accountInfo: p.virtualAthleteAccount,
        playerId
      });
    })
  );

  // SERVER_ONLY_TOGGLE
}

playerBundle__server__updateVirtualAthleteAccount.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
