import { AccountId, PrettyPlayerBundle, PlayerBundle, Player, PrettyPlayer } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { fetchPrettyPlayer } from "../../internal-utils/player-utils";

export async function playerBundle__server__getPrettyPlayerBundlesFromTeamId(p: {
  teamId: string;
}): Promise<{ prettyPlayer: PrettyPlayer; prettyPlayerBundle: PrettyPlayerBundle }[]> {
  // SERVER_ONLY_TOGGLE
  const q1 = p.teamId;
  let queryParams = [q1];

  const query = `select pb.item as player_bundle, p.item as player from mirror_player p,
  mirror_playerbundle pb where p.item->>'teamId' = $1 and p.item->>'linkedPlayerBundleId' = pb.id and coalesce(cast(p.item->>'deletedAtMS' as numeric), 0) = 0;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  if (r1.rowCount === 0) {
    return [];
  } else {
    const playersAndBundles = r1.rows.map(r => {
      const player = r.player as Player;
      const playerBundle = r.player_bundle as PlayerBundle;
      return { player, playerBundle };
    });

    const playersAndPrettyPlayerBundles = await Promise.all(
      _.compact(playersAndBundles).map(async playerAndBundle => {
        const prettyPlayerBundle = await fetchPrettyPlayerBundle(playerAndBundle.playerBundle);
        const prettyPlayer = await fetchPrettyPlayer(playerAndBundle.player);
        if (!prettyPlayerBundle || !prettyPlayer) {
          return null;
        }
        return { prettyPlayer, prettyPlayerBundle };
      })
    );

    return _.compact(playersAndPrettyPlayerBundles);
  }
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPrettyPlayerBundlesFromTeamId.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
