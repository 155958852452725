import { OrgRegistrationPackageId, PlayerBundleId } from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__updateExemptRegistrationPackages(p: {
  playerBundleId: PlayerBundleId;
  newExemptDetails: Record<OrgRegistrationPackageId, boolean>;
}) {
  console.log(p.newExemptDetails);
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const exemptDetailsWithMagicDelete = Object.keys(p.newExemptDetails).reduce((acc, val) => {
    acc[val] = p.newExemptDetails[val] === true ? true : h._MagicDeleteValue;
    return acc;
  }, {} as Record<OrgRegistrationPackageId, any>);

  console.log("------");
  console.log(exemptDetailsWithMagicDelete);

  await h.PlayerBundle.update({
    id: p.playerBundleId,
    doc: { exemptOrgRegistrationPackageIds: exemptDetailsWithMagicDelete }
  });
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__updateExemptRegistrationPackages.auth = () => {};

// i18n certified - complete
