import { PlayerBundleId, AccountId, PlayerBundle__LinkedPlayers, PlayerId, PlayerBundle } from "@ollie-sports/models";
import { canMergePlayerBundles, getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import _ from "lodash";
import { updatedLinkedTeamsForPlayerBundleId } from "./helpers/updatedLinkedTeamsForPlayerBundleId";
import { getUniversalHelpers } from "../../helpers";
import { translateServer } from "../../utils/serverTranslate";
import { ServerThisContext } from "@ollie-sports/react-bifrost";

export async function playerBundle__server__mergePlayerBundles(p: {
  playerBundleId01: PlayerBundleId;
  playerBundleId02: PlayerBundleId;
  selfAccountId: AccountId | "magic_cron_job_account_id";
  locale: string;
}): Promise<{ status: "success" } | { status: "failed"; reason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const pbs = await h.PlayerBundle.getDocs([p.playerBundleId01, p.playerBundleId02]);
  const mergeBundle = pbs[0];
  const oldBundle = pbs[1];

  if (!mergeBundle || !oldBundle) {
    throw new Error("Unable to find playerBundle");
  }

  let hasAccess =
    p.selfAccountId === "magic_cron_job_account_id" || //If users try to spoof this, the token validation will blow them up.
    !!(mergeBundle.managingAccounts?.[p.selfAccountId]?.exists && oldBundle.managingAccounts?.[p.selfAccountId]?.exists);

  if (!hasAccess) {
    //If they don't manage the player, ensure they at least are an org admin for one of the players.
    const [adminOrgs, teams1, teams2] = await Promise.all([
      h.Org.query({ where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }] }),
      h.Team.query({ where: [{ derived: { activePlayerBundleIds: { [mergeBundle.id]: ["==", true] } } }] }),
      h.Team.query({ where: [{ derived: { activePlayerBundleIds: { [oldBundle.id]: ["==", true] } } }] })
    ]);

    hasAccess = [...teams1.docs, ...teams2.docs].some(t => {
      return !!adminOrgs.docs.find(o => o.id === t.orgId);
    });
  }

  if (!hasAccess) {
    return {
      status: "failed",
      reason: translateServer({
        defaultMessage: "You do not have permission to merge these players together!",
        serverLocale: p.locale
      })
    };
  }

  const canMerge = await canMergePlayerBundles({ pb1: mergeBundle, pb2: oldBundle, locale: p.locale });

  if (canMerge.status === "allowed") {
    const tasks: BatchTask[] = [];

    // Mark the old playerBundle as deleted
    const oldBundleUpdate: Partial<PlayerBundle> = {
      deletedAtMS: Date.now(),
      derived: {
        linkedPlayers: _.mapValues(oldBundle.derived.linkedPlayers, b => ({ ...b, status: "inactive" as const }))
      }
    };

    tasks.push(await h.PlayerBundle.update({ id: oldBundle.id, doc: oldBundleUpdate }, { returnBatchTask: true }));

    // Fetch all players for both bundles
    const playersForMergeBundle = (
      await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", mergeBundle.id] }] })
    ).docs.filter(a => a.id);

    const playersForOldBundle = (await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", oldBundle.id] }] })).docs.filter(
      a => a.id
    );

    // consolidate all player links to bundle1
    for (let i = 0; i < playersForOldBundle.length; i++) {
      let bundle = playersForOldBundle[i];
      tasks.push(
        await h.Player.update({ id: bundle.id, doc: { linkedPlayerBundleId: mergeBundle.id } }, { returnBatchTask: true })
      );
    }

    //Update player bundle on registration related stuff...
    await Promise.all([
      h.OrgRegistration.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.OrgRegistration.update(
              { id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } },
              { returnBatchTask: true }
            )
          );
        }
      }),
      h.OrgInvoice.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.OrgInvoice.update({ id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } }, { returnBatchTask: true })
          );
        }
      }),
      h.OrgPayment.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.OrgPayment.update({ id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } }, { returnBatchTask: true })
          );
        }
      }),
      h.OrgPaymentRefund.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.OrgPaymentRefund.update(
              { id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } },
              { returnBatchTask: true }
            )
          );
        }
      }),
      h.ActionRequest.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.ActionRequest.update({ id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } }, { returnBatchTask: true })
          );
        }
      }),
      h.Code.query({ where: [{ playerBundleId: ["==", oldBundle.id] }] }).then(async a => {
        for (let i = 0; i < a.docs.length; i++) {
          tasks.push(
            await h.Code.update({ id: a.docs[i].id, doc: { playerBundleId: mergeBundle.id } }, { returnBatchTask: true })
          );
        }
      })
    ]);

    //Maybe delete a player or two from the team if merged players are on the same team
    const playersByTeam = [...playersForMergeBundle, ...playersForOldBundle]
      .filter(a => !a.deletedAtMS)
      .reduce((acc, pl) => {
        acc[pl.teamId] = acc[pl.teamId] || [];
        acc[pl.teamId].push(pl.id);
        return acc;
      }, {} as Record<string, string[]>);

    const teamsWithDupes = Object.keys(playersByTeam).filter(teamId => playersByTeam[teamId].length > 0);

    let dupePlayerIds: PlayerId[] = [];

    const now = Date.now();
    for (let i = 0; i < teamsWithDupes.length; i++) {
      const allPlayerIds = playersByTeam[teamsWithDupes[i]];
      const playerIdToKeep = playersForMergeBundle.find(pl => allPlayerIds.find(pid => pid === pl.id))?.id || allPlayerIds[0];
      dupePlayerIds = allPlayerIds.filter(id => id !== playerIdToKeep);
      for (let j = 0; j < dupePlayerIds.length; j++) {
        const playerId = dupePlayerIds[j];
        tasks.push(
          await h.Player.setPath(
            { id: playerId, pathObj: { deletedAtMS: true }, value: { deletedAtMS: now } },
            { returnBatchTask: true }
          )
        );
      }
    }

    // Update derived on player bundles
    const linkedPlayers: PlayerBundle__LinkedPlayers = {};
    [...playersForMergeBundle, ...playersForOldBundle].forEach(pl => {
      linkedPlayers[pl.id] = {
        status: pl.deletedAtMS === 0 && !dupePlayerIds.find(pid => pid == pl.id) ? "active" : "inactive",
        teamId: pl.teamId
      };
    });

    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: mergeBundle.id,
          pathObj: { derived: { linkedPlayers: true } },
          value: { derived: { linkedPlayers } }
        },
        { returnBatchTask: true }
      )
    );

    // Merge the accounts with an update
    const managingAccountsUpdate = _.omit(oldBundle.managingAccounts, Object.keys(mergeBundle.managingAccounts || {}));
    if (Object.keys(managingAccountsUpdate).length) {
      tasks.push(
        await h.PlayerBundle.update(
          {
            id: mergeBundle.id,
            doc: { managingAccounts: managingAccountsUpdate }
          },
          { returnBatchTask: true }
        )
      );
    }

    await h._BatchRunner.executeBatch(tasks);

    await updatedLinkedTeamsForPlayerBundleId({ playerBundleId: mergeBundle.id });

    return {
      status: "success"
    };
  } else if (canMerge.status === "not_allowed") {
    return {
      status: "failed",
      reason: canMerge.reason
    };
  } else {
    throw new Error("Unknown can merge status");
  }
  // SERVER_ONLY_TOGGLE
}
playerBundle__server__mergePlayerBundles.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
