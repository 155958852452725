import { PlayerBundleId, AccountId } from "@ollie-sports/models";
import { canMergePlayerBundles } from "../../compute/playerBundle.compute";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__canMergePlayerBundlesCheck(p: {
  playerBundleId01: PlayerBundleId;
  playerBundleId02: PlayerBundleId;
  selfAccountId: AccountId;
  locale: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const pbs = await h.PlayerBundle.getDocs([p.playerBundleId01, p.playerBundleId02]);
  const bundle1 = pbs[0];
  const bundle2 = pbs[1];
  if (!bundle1 || !bundle2) {
    throw new Error("Unable to find playerBundle");
  }
  return await canMergePlayerBundles({ pb1: bundle1, pb2: bundle2, locale: p.locale });
  // SERVER_ONLY_TOGGLE
}
playerBundle__server__canMergePlayerBundlesCheck.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
