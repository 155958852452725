import {
  AccountId,
  PrettyPlayerBundle,
  PlayerBundle,
  Account,
  Team,
  PlayerBundle__AccountType,
  TeamId,
  PlayerBundleId
} from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function playerBundle__server__getPlayerBundleIdsAndNameForTeamIds(p: {
  orgId: string;
  teamIds: TeamId[];
  selfAccountId: AccountId;
}): Promise<
  {
    playerBundleId: PlayerBundleId;
    playerName: string;
    guardianNames: string[];
    teamIds: string[];
  }[]
> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool, appOllieFirestoreV2: h } = getServerHelpers();

  const hasAccessProm = h.Org.getDoc(p.orgId).then(a => a?.accounts[p.selfAccountId]?.exists);

  const searchResults = await getAppPgPool()
    .query(
      `select a.*, ((acc.item ->> 'firstName')::text || ' ' || (acc.item ->> 'lastName')::text) as managing_account_name
      from (select v1.player_bundle_id                                         as player_bundle_id,
                   (v1.first_name || ' ' || v1.last_name)                      as player_name,
                   (jsonb_each(pb.item -> 'managingAccounts')).key             as managing_account_id,
                   (jsonb_each(pb.item -> 'managingAccounts')).value -> 'type' as managing_account_type,
                   v2.team_id as team_id
            from f_player_bundles_with_name_id_and_self_athlete_account_id($2, null) v1,
            f_org_player_bundles_with_team_id($2) v2,
                 mirror_playerbundle pb
            where v1.player_bundle_id = v2.player_bundle_id
              and v2.team_id = any ($1::text[])
              and pb.id = v1.player_bundle_id) a,
           mirror_account acc
      where acc.id = a.managing_account_id;`,
      [p.teamIds, p.orgId]
    )
    .then(a =>
      a.rows.map(row => {
        return {
          playerBundleId: row["player_bundle_id"],
          playerName: row["player_name"],
          managingAccountId: row["managing_account_id"],
          managingAccountType: row["managing_account_type"],
          managingAccountName: row["managing_account_name"],
          teamId: row["team_id"]
        } as {
          playerBundleId: string;
          playerName: string;
          managingAccountId: string;
          managingAccountType: string;
          managingAccountName: string;
          teamId: string;
        };
      })
    );

  if (!(await hasAccessProm)) {
    throw new Error("Unable to search requested org!");
  }

  const ret: { playerBundleId: PlayerBundleId; guardianNames: string[]; teamIds: string[]; playerName: string }[] = [];
  _(searchResults)
    .groupBy(a => a.playerBundleId)
    .map(v => {
      ret.push({
        playerBundleId: v[0].playerBundleId,
        guardianNames: _(v)
          .filter(b => b.managingAccountType === "guardian")
          .uniqBy(b => b.managingAccountId)
          .map(b => b.managingAccountName)
          .value(),
        teamIds: v.map(a => a.teamId),
        playerName: v[0].playerName
      });
    })
    .value();

  return ret;

  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPlayerBundleIdsAndNameForTeamIds.auth = (r: express.Request) => {
  return validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
