import { AccountId, PrettyPlayerBundle, PlayerBundle } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function playerBundle__server__getPlayerBundle(p: { id: string }): Promise<PlayerBundle | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const a1 = await h.PlayerBundle.getDoc(p.id);

  return a1;
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPlayerBundle.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
