import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { PrettyPlayerBundle } from "@ollie-sports/models";
import PromiseQueue = require("promise-queue");
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../internal-utils/player-bundle-utils";
import { getManagedPlayerBundlesForAccountIdFragment } from "../query-fragments/playerBundle.fragments";
import { getUniversalHelpers } from "../helpers";

export function playerBundle__client__managedPrettyPlayerBundlesSubscription(p: {
  selfAccountId: string;
}): BifrostSubscription<PrettyPlayerBundle[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: Function[] = [];
  const instance = createBifrostSubscription<PrettyPlayerBundle[]>({
    dispose: () => {
      try {
        disposeFns.forEach(fn => fn());
      } catch (e) {
        console.error(e);
      }
    }
  });

  const promiseQueue = new PromiseQueue(1);

  const sub = h.PlayerBundle.querySubscription(getManagedPlayerBundlesForAccountIdFragment(p.selfAccountId)).subscribe(
    s => {
      promiseQueue.add(async () => {
        instance.nextData(await fetchPrettyPlayerBundleList(s.docs));
      });
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

export function playerBundle__client__PrettyPlayerBundleSingleDocSubscription(p: {
  id: string;
}): BifrostSubscription<PrettyPlayerBundle | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const a1 = h.PlayerBundle.docSubscription(p.id);

  const promiseQueue = new PromiseQueue(1);
  const a2 = a1.subscribe(
    data => {
      promiseQueue.add(async () => {
        if (!data) {
          sub.nextData(undefined);
        } else {
          sub.nextData(await fetchPrettyPlayerBundle(data));
        }
      });
    },
    e => {
      console.error(e.message);
    }
  );

  const sub = createBifrostSubscription<PrettyPlayerBundle | undefined>({
    dispose: () => {
      a2.unsubscribe();
    }
  });

  return sub;
}

// i18n certified - complete
