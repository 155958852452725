import { PlayerBundleId, AccountId, PlayerBundle__AccountType } from "@ollie-sports/models";
import _ from "lodash";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { extractSelfAccountIdFromPlayerBundle } from "../../compute/playerBundle.compute";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__changeManagedAccountType(p: {
  playerBundleId: PlayerBundleId;
  managedAccountId: AccountId;
  type: PlayerBundle__AccountType;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const playerBundle = await h.PlayerBundle.getDoc(p.playerBundleId);

  if (!playerBundle) {
    throw new Error("Unable to find playerBundle");
  }

  if (!playerBundle.managingAccounts?.[p.managedAccountId]) {
    throw new Error(
      `User already does not manage this playerBundle PlayerBundleId. Cannot change the type: ${p.playerBundleId} . ManagedAccountId: ${p.managedAccountId} `
    );
  }

  if (p.type === PlayerBundle__AccountType.selfAthlete) {
    const playerBundleSelfAccountId = extractSelfAccountIdFromPlayerBundle({
      bundle: playerBundle
    });
    if (playerBundleSelfAccountId && playerBundleSelfAccountId !== p.managedAccountId) {
      throw new Error(
        `PlayerBundle already has a managed account that claims the profile for itself. Make it a guardian before trying to assign another self type. PlayerBundleId: ${p.playerBundleId} ManagedAccountId: ${p.managedAccountId} AccountIdClaimingProfileForSelf: ${playerBundleSelfAccountId}`
      );
    }
  }

  await h.PlayerBundle.update({
    id: p.playerBundleId,
    doc: { managingAccounts: { [p.managedAccountId]: { exists: true, type: p.type } } }
  });
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__changeManagedAccountType.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
