import { AccountId, PrettyPlayerBundle, PlayerBundle } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function playerBundle__server__getPrettyPlayerBundles(p: { ids: string[] }): Promise<PrettyPlayerBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const a1 = await h.PlayerBundle.getDocs(p.ids);
  if (!a1) {
    return [];
  }
  const prettyPlayerBundles = await Promise.all(_.compact(a1).map(async bundle => await fetchPrettyPlayerBundle(bundle)));
  return _.compact(prettyPlayerBundles);
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPrettyPlayerBundles.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
