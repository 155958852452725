import { PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { updateDerivedForTeam } from "../../../internal-utils/team-utils";
import { getUniversalHelpers } from "../../../helpers";

// Important to run after add/removing a managing account to/from a profile so that they have access to the various teams
export async function updatedLinkedTeamsForPlayerBundleId(p: { playerBundleId: PlayerBundleId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let linkedPlayers = (
    await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", p.playerBundleId] }, { deletedAtMS: ["==", 0] }] })
  ).docs;
  let linkedTeamIds = _.uniq(linkedPlayers.map(pl => pl.teamId));

  await Promise.all(
    linkedTeamIds.map(async teamId => {
      try {
        await updateDerivedForTeam({ teamId, executeImmediate: true });
      } catch (e) {
        console.error(`Trouble updating linked team for playerBundleId. PlayerBundleId: ${p.playerBundleId} TeamId: ${teamId}`);
      }
    })
  );

  return linkedTeamIds;
}

// i18n certified - complete
