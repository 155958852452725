import { PlayerBundleId, AccountId } from "@ollie-sports/models";
import _ from "lodash";
import { updatedLinkedTeamsForPlayerBundleId } from "./helpers/updatedLinkedTeamsForPlayerBundleId";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__removeManagedAccount(p: {
  playerBundleId: PlayerBundleId;
  managedAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.PlayerBundle.update({
    id: p.playerBundleId,
    doc: { managingAccounts: { [p.managedAccountId]: h._MagicDeleteValue } }
  });
  await updatedLinkedTeamsForPlayerBundleId({ playerBundleId: p.playerBundleId });
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__removeManagedAccount.auth = async (r: express.Request) => {
  await validateToken(r);
};
// i18n certified - complete
