import { CODE_TYPES, PlayerBundleId, CodeTeam, CodeProfile } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { getExpiryDate } from "../teams.api";
import { findUnusedCode } from "../common.api";

export async function playerBundle__server__getInviteCode(p: {
  playerBundleId: PlayerBundleId;
  type: CODE_TYPES.joinProfileAsAthlete | CODE_TYPES.joinProfileAsGuardian;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let code = await findUnusedCode();

  const codeObj: CodeProfile = {
    id: h.Code.generateId(),
    createdAtMS: Date.now(),
    playerBundleId: p.playerBundleId,
    codeType: p.type,
    expiryDateMS: getExpiryDate(),
    code: code
  };
  await h.Code.add({ doc: codeObj });

  return codeObj;
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getInviteCode.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
