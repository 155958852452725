import { PlayerBundleId } from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__updateProfileImageUris(p: {
  playerBundleId: PlayerBundleId;
  profileImageUri: string;
  profileImageUriSmall: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.PlayerBundle.update({
    id: p.playerBundleId,
    doc: { virtualAthleteAccount: { profileImageUri: p.profileImageUri, profileImageUriSmall: p.profileImageUriSmall } }
  });
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__updateProfileImageUris.auth = () => {};

// i18n certified - complete
