import { AccountId, PrettyPlayerBundle, PlayerBundle, Player, PlayerBundle__AccountType } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function playerBundle__server__addPlayerBundle(p: {
  player: Player;
  selfAccountId: AccountId;
  type: PlayerBundle__AccountType;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const id = h.PlayerBundle.generateId();

  const team = await h.Team.getDoc(p.player.teamId);
  if (!team) {
    throw new Error("Unable to find team!");
  }

  await h.PlayerBundle.add({
    doc: {
      id: id,
      createdAtMS: Date.now(),
      deletedAtMS: 0,
      virtualAthleteAccount: p.player.virtualAthleteAccount,
      managingAccounts: { [p.selfAccountId]: { exists: true, type: p.type } },
      derived: {
        activeLinkedOrgs: team.orgId ? { [team.orgId]: true } : {},
        linkedPlayers: { [p.player.id]: { status: "active", teamId: p.player.teamId } }
      }
    }
  });

  return id;
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__addPlayerBundle.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
