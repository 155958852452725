import {
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgPayment,
  Player,
  PlayerBundle,
  PrettyPlayer,
  PrettyPlayerBundle
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { isParentOrgInvoice } from "../../utils";
import { fetchPrettyPlayerList } from "../../internal-utils/player-utils";
import { fetchPrettyPlayerBundleList } from "../../internal-utils/player-bundle-utils";

export async function playerBundle__server__getOrgPlayerBundles(p: {
  orgId: OrgId;
  selfAccountId: string;
  offset?: number;
  limit?: number;
  searchTerm?: string;
  includeInactive?: boolean;
  sort?: ["name", "asc" | "desc"][]; //field, direction
}): Promise<{ count: number; data: PrettyPlayerBundle[] }> {
  // }): Promise<{ count: number; data: { player: Player; playerBundle?: PlayerBundle }[] }> {
  const { getAppPgPool } = getServerHelpers();

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    offset: { valid: true, val: p.offset || 0 },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    limit: { valid: true, val: p.limit || 100 }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const query = `select player_bundle,
  count(*) OVER () AS count from
  (select player_bundle, a.item as account
    from (select pb.item as player_bundle
          from mirror_player p,
               mirror_team t,
               mirror_playerbundle pb
          where t.id = p.item ->> 'teamId'
            and pb.id = p.item->>'linkedPlayerBundleId'
            and t.item ->> 'orgId' = ${queryParams.orgId!.sqlParam}
            ${p.includeInactive ? `` : `and p.item ->> 'deletedAtMS' = '0'`}) player_bundles
             left join (SELECT item ->> 'id' AS player_bundle_id,
                               (SELECT key
                                FROM jsonb_each(item -> 'managingAccounts')
                                WHERE value ->> 'type' = 'selfAthlete'
                                LIMIT 1)     AS account_id
                        FROM mirror_playerbundle) self_athlete_ids on self_athlete_ids.player_bundle_id = player_bundles.player_bundle->>'id'
             left join mirror_account a on a.id = self_athlete_ids.account_id
             where true
             ${
               queryParams.searchTerm
                 ? `and CASE WHEN a.item IS NOT NULL THEN trim(concat(a.item ->> 'firstName',a.item ->> 'lastName')) ilike ${queryParams.searchTerm.sqlParam}
                             ELSE trim(concat(player_bundle -> 'virtualAthleteAccount' ->> 'firstName',player_bundle -> 'virtualAthleteAccount' ->> 'lastName')) ilike ${queryParams.searchTerm.sqlParam} END`
                 : ""
             }
    ) a group by player_bundle, a.account
    ORDER BY CASE
    WHEN a.account IS NOT NULL THEN CONCAT(a.account ->> 'firstName',
                                        a.account ->> 'lastName')
    ELSE CONCAT(player_bundle -> 'virtualAthleteAccount' ->> 'firstName',
                                         player_bundle -> 'virtualAthleteAccount' ->> 'lastName')
    END ASC
                 OFFSET ${queryParams.offset!.sqlParam} LIMIT ${queryParams.limit!.sqlParam};`;

  const r1 = await getAppPgPool().query(
    query,
    Object.values(queryParams).map(a => a.value)
  );

  if (!r1.rowCount) {
    return {
      data: [],
      count: 0
    };
  } else {
    return {
      count: r1.rows[0].count,
      data: await fetchPrettyPlayerBundleList(r1.rows.map(row => row.player_bundle as PlayerBundle))
    };
  }
}

playerBundle__server__getOrgPlayerBundles.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
