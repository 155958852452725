import { PlayerBundle__AccountType, Account, ActionRequest } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { emitMarketingEvent, ObjectKeys } from "../../utils";
import { notification__server__triggerForProfileResponse } from "../notification.api";

export async function playerBundle__server__respondToPlayerBundleJoinRequest(p: {
  actionRequest: ActionRequest;
  respondingAccount: Account;
  approve: boolean;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const tasks: any = [];
  const joinedTeamIds = [];
  const playerBundle = await h.PlayerBundle.getDoc(p.actionRequest.playerBundleId);

  if (!playerBundle) {
    throw new Error(`Invalid PlayerBundleId: ${p.actionRequest.playerBundleId}.`);
  }

  const requestingAccount = await h.Account.getDoc(p.actionRequest.requestingAccountId);

  if (!requestingAccount) {
    throw new Error(`Invalid RequestingAccountId: ${p.actionRequest.requestingAccountId}.`);
  }

  const alreadyLinked = playerBundle.managingAccounts?.[p.actionRequest.requestingAccountId];
  const hasPermission = playerBundle.managingAccounts?.[p.respondingAccount.id];

  let result: "approved" | "rejected" | "already-linked" | "does-not-have-permission";
  if (p.approve) {
    if (alreadyLinked) {
      result = "already-linked";
    } else if (!hasPermission) {
      result = "does-not-have-permission";
    } else {
      result = "approved";
    }
  } else {
    result = "rejected";
  }

  if (result === "approved") {
    // Add the account as a managing account
    tasks.push(
      await h.PlayerBundle.update(
        {
          id: p.actionRequest.playerBundleId,
          doc: {
            managingAccounts: { [p.actionRequest.requestingAccountId]: { exists: true, type: p.actionRequest.accountType } }
          }
        },
        { returnBatchTask: true }
      )
    );
    const roles = p.actionRequest.accountType === PlayerBundle__AccountType.selfAthlete ? { athlete: true } : { guardian: true };
    // Add the account to each of the teams as their role
    const playerIds = ObjectKeys(playerBundle.derived.linkedPlayers);
    for (let i = 0; i < playerIds.length; i++) {
      const playerItem = playerBundle.derived.linkedPlayers[playerIds[i]];
      if (playerItem.status === "active") {
        joinedTeamIds.push(playerItem.teamId);
        tasks.push(
          await h.Team.update(
            {
              id: playerItem.teamId,
              doc: {
                accounts: {
                  [p.actionRequest.requestingAccountId]: {
                    exists: true,
                    roles,
                    additionalPermissions: {}
                  }
                }
              }
            },
            { returnBatchTask: true }
          )
        );
      }
    }
  }

  tasks.push(
    await h.ActionRequest.update(
      {
        id: p.actionRequest.id,
        doc: {
          completedDateMS: Date.now(),
          status: p.approve ? "approved" : "denied",
          completedByAccountId: p.respondingAccount.id
        }
      },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(tasks);

  await notification__server__triggerForProfileResponse({
    accountType: p.actionRequest.accountType,
    result,
    playerBundle,
    requestingAccount: requestingAccount,
    respondingAccount: p.respondingAccount,
    actionRequestId: p.actionRequest.id
  });
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__respondToPlayerBundleJoinRequest.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

// i18n certified - complete
