import { AccountId, PrettyPlayerBundle } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function playerBundle__server__getManagedPrettyPlayerBundlesForAccountId(p: {
  accountId: AccountId;
}): Promise<PrettyPlayerBundle[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const r1 = await h.PlayerBundle.query(getManagedPlayerBundlesForAccountIdFragment(p.accountId));
  return await fetchPrettyPlayerBundleList(r1.docs);
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getManagedPrettyPlayerBundlesForAccountId.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
