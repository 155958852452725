import { AccountId, PrettyPlayerBundle, PlayerBundle } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function playerBundle__server__getPrettyPlayerBundle(p: { id: string }): Promise<PrettyPlayerBundle | undefined> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const a1 = await h.PlayerBundle.getDoc(p.id);
  if (!a1) {
    return undefined;
  }
  const prettyPlayerBundle = await fetchPrettyPlayerBundle(a1);
  return prettyPlayerBundle;
  // SERVER_ONLY_TOGGLE
}

playerBundle__server__getPrettyPlayerBundle.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
